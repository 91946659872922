<template>
    <div>
      <!-- <PageLoader />
      <ServerError v-if="ServerError" /> -->
      <v-app id="inspire">
        <v-layout wrap justify-center class="login_page">
          <v-flex xs12 sm6 md4 lg3 px-2 align-self-center>
            <v-layout wrap justify-center>
              <v-flex xs112 xl10>
                <v-snackbar
                  v-model="showSnackBar"
                  color="black"
                  right
                  :timeout="timeout"
                >
                  <v-layout wrap justify-center>
                    <v-flex text-left class="align-self-center">
                      <span style="color: #fff">
                        {{ msg }}
                      </span>
                    </v-flex>
                    <v-flex text-right>
                      <v-btn
                        small
                        :ripple="false"
                        text
                        @click="showSnackBar = false"
                      >
                        <v-icon style="color: #fff">mdi-close</v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-snackbar>
                <v-card tile flat>
                  <v-layout wrap justify-center>
                    <v-flex px-8 py-6>
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-center>
                          <span
                            style="
                            font-family: poppinsmedium;
                              font-size: 15px;
                              color: #b8b8b8;
                            "
                          >
                            Welcome to
                          </span>
                        </v-flex>
                        <v-flex xs12 text-center py-4 style="cursor: pointer">
                          <!-- <router-link to="/"> -->
                            <span
                              style="
                               font-family: poppinsbold;
                                font-size: 20px;
                                cursor: pointer;
                                color: #545454;
                              "
                            >
                              BGF
                            </span>
                          <!-- </router-link> -->
                        </v-flex>
                        <v-flex xs12>
                          <v-layout wrap justify-center>
                            <v-flex xs6 lg4 text-center px-2>
                              <span
                                style="
                                font-family: poppinssemibold;
                                  font-size: 15px;
                                  color: #000;
                                "
                              >
                                Log In
                              </span>
                              <v-progress-linear
                                height="2"
                                value="100"
                                color="#3CB043"
                              ></v-progress-linear>
                            </v-flex>
                            <!-- <v-flex xs6 lg4 px-2>
                              <router-link to="/SignUp">
                                <span
                                  style="
                                    font-family: poppinssemibold;
                                    font-size: 15px;
                                    color: #000;
                                  "
                                >
                                  Sign up
                                </span>
                              </router-link>
                            </v-flex> -->
                          </v-layout>
                        </v-flex>
                        <v-flex pt-8 xs12 text-left>
                          <v-layout wrap justify-center>
                            <v-flex xs12 pb-2>
                              <span
                                style="
                                font-family: poppinsregular;
                                  font-size: 12px;
                                  color: #ababab;
                                "
                              >
                                Email
                              </span>
                            </v-flex>
                            <v-flex xs12>
                              <v-form @submit.prevent="validateInput">
                                <v-text-field
                                  color="#717171"
                                  placeholder="Email"
                                  outlined
                                  dense
                                  v-model="userName"
                                  hide-details
                                >
                                </v-text-field>
                              </v-form>
                            </v-flex>
                          </v-layout>
                          <v-layout wrap justify-center pt-2>
                            <v-flex xs12 pb-2>
                              <span
                                style="
                                font-family: poppinsregular;
                                  font-size: 12px;
                                  color: #ababab;
                                "
                              >
                                Password
                              </span>
                            </v-flex>
                            <v-flex xs12>
                              <v-form @submit.prevent="validateInput">
                                <v-text-field
                                  color="#717171"
                                  placeholder="Password"
                                  type="password"
                                  outlined
                                  dense
                                  v-model="password"
                                  hide-details
                                >
                                </v-text-field>
                              </v-form>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <!-- <v-flex pt-2 xs12 text-left>
                          <router-link to="/ForgotPassword">
                            <span
                              style="
                                font-family: poppinssemibold;
                                font-size: 12px;
                                text-transform: none;
                              "
                            >
                              Forgot Password ?
                            </span>
                          </router-link>
                        </v-flex> -->
                        <v-flex xs12 py-6>
                          <v-btn
                            block
                            tile
                            color="#3CB043"
                            dark
                            :ripple="false"
                            depressed
                            @click="validateInput"
                            class="itemValue"
                          >
                            Continue
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-app>
    </div>
  </template>
  <script>
  import axios from "axios";
  import store from './../../store'
  export default {
    data() {
      return {
        ServerError: false,
        showSnackBar: false,
        timeout: 5000,
        msg: null,
        userName: null,
        password: null,
      };
    },
    methods: {
      validateInput() {
        if (!this.userName) {
          this.msg = "Please Provide Email";
          this.showSnackBar = true;
          return;
        } else if (!this.password) {
          this.msg = "Please Provide Password";
          this.showSnackBar = true;
          return;
        } else {
          this.login();
        }
      },
      login() {
        var userData = {};
        // userData.accessType = "signin";
        userData["userName"] = this.userName;
        userData["password"] = this.password;
        store.commit("appLoading", true);
        axios({
          method: "POST",
          url: "/admin/login",
          data: userData,
        })
          .then((response) => {
            store.commit("appLoading", false);
            if (response.data.status) {
              store.commit("userType", response.data.role);
              store.commit("userData", response.data);
              store.commit("loginUser", response.data.token);
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            store.commit("appLoading", false);
            this.ServerError = true;
            console.log(err);
          });
      },
    },
  };
  </script>
  <style>
  .login_page {
    background-color: #2aa86638;
    background-image: url("./../../assets/images/Background.png");
    background-attachment: fixed;
    background-size: cover;
  }
  </style>